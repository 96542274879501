import * as constants from "../utils/constants";
import { GetStaticProps } from "next";
import GeneralPage, { GeneralPageProps } from "components/pages/GeneralPage";
import ssg from "ssg/ssg-api";

export const getStaticProps: GetStaticProps = async ({
  preview = constants.IS_PREVIEW_ENV,
}) => {
  const config = await ssg(preview).getWebsitePageByATMSlug("home");

  // Temporarily hardcode this page here. TODO: Implement all website pages
  let props = {};
  if (config.content?.component === "generalPage") {
    props = {
      isPreview: preview,
      fullSlug: "/",
      seo: config.content?.seo,
      sections: config.content?.sections,
    };
  }

  // Below is for this reason:
  // https://github.com/vercel/next.js/discussions/11209#discussioncomment-35915

  return {
    props: JSON.parse(JSON.stringify(props)) as typeof props,
    revalidate: false,
  };
};

export default function Home(props: GeneralPageProps) {
  return <GeneralPage {...props} />;
}
