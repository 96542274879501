import styled from "styled-components";
import screenSize from "styles/mediaQueries";
import { headerHeight, headerHeightDesktop } from "components/ui/Layout";

export const Wrapper = styled.div`
  overflow: hidden;
  padding-top: ${headerHeight};

  ${screenSize.minDesktop`
    padding-top: ${headerHeightDesktop};
  `}
`;
