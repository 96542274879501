import SectionsMapper from "components/lib/SectionsMapper";
import { BasePageProps, SEO as SEOType } from "types/attheminute";
import { SectionContext } from "types/sectionContext";
import Navigation from "components/ui/Navigation";
import Footer from "components/ui/Footer";
import SEO from "components/ui/SEO";

import { SectionProps } from "types/sectionContext";
import ThemeProvider from "components/hocs/ThemeProvider";

import * as Styled from "./GeneralPage.styled";
import ShopNavigation from "components/ui/Navigation/ShopNavigation";

export interface GeneralPageProps extends BasePageProps {
  isPreview: boolean;
  useTeachingNavigation?: boolean;
  fullSlug: string;
  seo?: SEOType;
  sections?: SectionContext<SectionProps>[];
}

function GeneralPage(props: GeneralPageProps) {
  const { fullSlug, seo, sections, useTeachingNavigation } = props;

  return (
    <ThemeProvider>
      {seo ? (
        <SEO
          title={seo.title}
          description={seo.description}
          fullSlug={fullSlug}
          imageURL={seo.shareImage}
          imageAlt={seo.shareImageAlt}
          tags={seo.tags}
        />
      ) : null}
      {useTeachingNavigation ? <ShopNavigation /> : <Navigation />}
      {sections && sections.length ? (
        <Styled.Wrapper>
          <SectionsMapper sections={sections} />
        </Styled.Wrapper>
      ) : null}
      <Footer />
    </ThemeProvider>
  );
}

export default GeneralPage;
